import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Row, Col, Drawer, Button, Icon } from "antd";
import Logo from "../../assets/images/Sentiment_Logo.png";
import "../../scss/header.scss";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

class Header extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  showModal = () => {
    this.setState({
      visibleChangePass: true,
    });
  };

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 991.98 });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };
  render() {
    const isDesktop = this.state.isDesktop;
    return (
      <div>
        {isDesktop ? (
          <>
            <Row
              className="bg_white"
              type="flex"
              justify="space-between"
              align="middle"
              style={{ padding: "20px" }}
            >
              <Col
                sm={{ span: 22, push: 0 }}
                md={{ span: 22, push: 2 }}
                lg={{ span: 11, push: 1 }}
                className="cover_logo"
              >
                <Link to="/" className="text_semibold text_white">
                  <img
                    src={Logo}
                    style={{
                      height: "45px",
                    }}
                    alt="logo"
                  />
                </Link>
              </Col>
              <Col
                sm={{ span: 22, pull: 0 }}
                md={{ span: 22, pull: 2 }}
                lg={{ span: 11, pull: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  margin: "5px 0",
                }}
              >
                <div>
                  <Button type="link" size="large">
                    {/* <Link to="/contact-us" className="text_semibold">
                      CONTACT US
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/contact-us">
                      CONTACT US
                    </a>
                  </Button>
                  <Button type="link" size="large">
                    {/* <Link to="/faqs" className="text_semibold">
                      FAQ'S
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/faq">
                      FAQ'S
                    </a>
                  </Button>
                  <Button
                    className="cover_button_outline"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    {/* <Link to="/sign_in" className="text_semibold ">
                      LOG IN
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/login">
                      LOG IN
                    </a>
                  </Button>
                  <Button
                    className="cover_button"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    {/* <Link to="/register" className="text_semibold">
                      REGISTER
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/signup">
                      REGISTER
                    </a>
                  </Button>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col span={24} className="bg_white">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "1em" }}>
                  <img
                    src={Logo}
                    style={{
                      height: "32px",
                    }}
                    alt="logo"
                  />
                </div>
                <Button
                  type="link"
                  onClick={this.showDrawer}
                  style={{ float: "right" }}
                >
                  <Icon type="menu" className="text_app_color" />
                </Button>
              </div>

              <Drawer
                placement="left"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                bodyStyle={{ padding: 0 }}
              >
                <Menu
                  onClick={this.handleClick}
                  style={{ width: "auto" }}
                  selectedKeys={[this.props.location.pathname]}
                  mode="inline"
                >
                  <Menu.Item key="/contact-us">
                    {/* <Link to="/contact-us" className="text_semibold">
                      CONTACT US
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/contact-us">
                      CONTACT US
                    </a>
                  </Menu.Item>
                  <Menu.Item key="/faqs">
                    {/* <Link to="/faqs" className="text_semibold">
                      FAQ'S
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/faq">
                      FAQ'S
                    </a>
                  </Menu.Item>
                  <Button
                    className="cover_button_outline"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    {/* <Link to="/sign_in" className="text_semibold ">
                      LOG IN
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/login">
                      LOG IN
                    </a>
                  </Button>
                  <Button
                    className="cover_button"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    {/* <Link to="/register" className="text_semibold">
                      REGISTER
                    </Link> */}
                    <a className="text_semibold" href="https://app.sentimentglobal.com/signup">
                      REGISTER
                    </a>
                  </Button>
                </Menu>
              </Drawer>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withRouter(Header);
