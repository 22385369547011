import { useEffect } from "react";

export const Register = () => {
  useEffect(() => {
    window.location.replace(`https://app.sentimentglobal.com/signup`);
  }, []);

  return null;
}

export default Register;