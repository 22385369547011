import { useEffect } from "react";

export const ForgotPassword = () => {
  useEffect(() => {
    window.location.replace(`https://app.sentimentglobal.com/forgot-password`);
  }, []);

  return null;
}

export default ForgotPassword;