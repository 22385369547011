/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Modal,
  Radio,
  DatePicker,
  Select,
  Input,
  notification,
} from "antd";
import {
  APIRequest,
  API_SEE_AVAILABILITY,
  API_SCHEDULE_INTERVIEW,
  API_TIME_ZONE,
} from "../../api";
import moment from "moment";
import { connect } from "react-redux";

const { Text } = Typography;
const { Option } = Select;
const { confirm } = Modal;
const { TextArea } = Input;

export class Schedulebuttons extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      visible1: false,
      visible2: false,
      visibleReschedule: false,
      dataAvailability: [],
      allocatedDateTimes: [],
      studyId: "",
      searchDate: "",
      review: "",
      name: "",
      cancelReason: null,
      visibleCancelM: false,
      selectedSlot: null,
      status: 0,
      reviewStudyId: "",
      interviewTime: "",
      interviewDate: "",
      interviewTimezone: "",
      interviewNote: "",
      interviewStaus: null,
      rescheduleReason: null,
      timezoneList: [],
      selectedTimezone: "",
      reviewData: null,
      visible_submit_review: false,
      slotArray: {},
      dateArray: [],
    };
  }

  showModal = (e, dataAvailability, status, studyTz) => {
    this.setState(
      {
        status: status,
        visible: true,
        studyId: e.uuid,
        interviewStaus: e.schedule_date ? e.schedule_date.status : null,
        studyTimezone: studyTz,
      },
      () => {
        this.avaibility(dataAvailability);

        // this.state.interviewStaus === 1 ||
        //   (this.state.interviewStaus === 2 &&
        //     this.setState({
        //       selectedTimezone: this.state.studyTimezone
        //     }));
      }
    );
  };

  showModal1 = (e, dataAvailability) => {
    this.setState(
      {
        studyId: e.uuid,
        visible1: true,
        visible: false,
        interviewNote:null,
        currentSelectedTime:
          dataAvailability[
            dataAvailability.findIndex(
              (item) => moment().format("MM/DD/YYYY") === item.date
            )
          ],
      },
      () => {
        this.avaibility(dataAvailability);
      }
    );
  };

  showRescheduleModal = () => {
    this.setState({
      visibleReschedule: true,
      visible: false,
    });
  };

  closeModal = (e) => {
    this.setState({
      visible: false,
      visible_submit_review: false,
      reviewData: null,
    });
  };

  onChangeDate = (date, dateString) => {
    this.setState(
      {
        searchDate: dateString,
      },
      () => {
        this.avaibility();
      }
    );
  };

  onChangeNote = (e) => {
    this.setState({
      interviewNote: e.target.value,
    });
  };

  avaibility = (e) => {
    new APIRequest.Builder()
      .post()
      .setReqId(API_SEE_AVAILABILITY)
      .jsonParams({
        study_id: this.state.studyId,
        date: this.state.searchDate,
      })
      .reqURL("/see-availability")
      .response(this.onResponse)
      .error(this.onError)
      .build()
      .doRequest();
  };

  scheduleInterview = (date, time, interview_notes) => {
    new APIRequest.Builder()
      .post()
      .setReqId(API_SCHEDULE_INTERVIEW)
      .jsonParams({
        study_id: this.state.studyId,
        date,
        timezone: this.state.selectedTimezone,
        time,
        additional_notes: interview_notes,
      })
      .reqURL("/schedule")
      .response(this.onResponse)
      .error(this.onError)
      .build()
      .doRequest();

    this.setState({
      visible: false,
      visible1: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      visible1: false,
      visible2: false,
      visible_submit_review: false,
      visibleReschedule: false,
      selectedSlot: null,
      visibleCancelM: false,
      review: "",
    });
  };

  handleBack = (e) => {
    this.setState({
      visible: true,
      visible1: false,
      visible2: false,
      visibleReschedule: false,
    });
  };

  onChange = (e) => {
    this.setState({
      selectedSlot: e.target.value,
      interviewTime: moment(
        e.target.value.split(" ").slice(1, 4).join(" "),
        "YYYY-MM-DD HH:mm:ss"
      ).format("HH:mm:ss"),
      interviewDate: moment(
        e.target.value.split(" ").slice(1, 4).join(" "),
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD"),
    });
  };

  fetchTimeZone() {
    new APIRequest.Builder()
      .get()
      .setReqId(API_TIME_ZONE)
      .reqURL("/get-timezone")
      .response(this.onResponse)
      .error(this.onError)
      .build()
      .doRequest();
  }

  componentDidMount() {
    this.setState({
      selectedTimezone:
        this.props.timezoneProps && this.props.timezoneProps.timezone,
      timezoneName: this.props.timezoneProps && this.props.timezoneProps.name,
    });
    this.fetchTimeZone();
  }

  changeTimeZone = (value, title) => {
    this.setState(
      {
        selectedTimezone: value,
        timezoneName: title.props.title,
      },
      () => {
        this.avaibility();
      }
    );
  };

  dateTimeChange() {
    let slotArrayTemp = {};
    var dateArrayTemp = this.state.dataAvailability
      .filter(
        (item, index, self) =>
          self.findIndex((selfItem) => selfItem.date === item.date) === index
      )
      .map((item) => item.date);

    var dateArrayTemp2 = this.state.dataAvailability
      .filter(
        (item, index, self) =>
          self.findIndex(
            (selfItem) =>
              moment
                .utc(selfItem.start_date_time, "YYYY-MM-DD HH:mm:ss")
                .tz(this.state.selectedTimezone)
                .format("YYYY-MM-DD") ===
              moment
                .utc(item.start_date_time, "YYYY-MM-DD HH:mm:ss")
                .tz(this.state.selectedTimezone)
                .format("YYYY-MM-DD")
          ) === index
      )
      .map((item) =>
        moment
          .utc(item.start_date_time, "YYYY-MM-DD HH:mm:ss")
          .tz(this.state.selectedTimezone)
          .format("YYYY-MM-DD")
      );

    dateArrayTemp2.map((e) => {
      slotArrayTemp[e] = this.state.dataAvailability
        .filter(
          (item) =>
            moment
              .utc(item.start_date_time, "YYYY-MM-DD HH:mm:ss")
              .tz(this.state.selectedTimezone)
              .format("YYYY-MM-DD") === e
        )
        .sort();
    });

    dateArrayTemp2.map((e) => {
      slotArrayTemp[e].sort(function (a, b) {
        return new Date(a.start_date_time) - new Date(b.start_date_time);
      });
    });

    this.setState({
      slotArray: slotArrayTemp,
      dateArray: dateArrayTemp2,
    });
  }

  onResponse = (response, reqId) => {
    switch (reqId) {
      case API_SEE_AVAILABILITY:
        this.setState(
          {
            dataAvailability: response.data.study.study_date_times,
            allocatedDateTimes: response.data.allocatedDateTimes,
          },
          () => {
            this.dateTimeChange();
          }
        );
        break;
      case API_SCHEDULE_INTERVIEW:
        this.props.fetchStudyRecord();
        Modal.success({
          title: "Thank you!",
          centered: "true",
          icon: "none",
          content: (
            <p>
              Your interview is scheduled on&nbsp;
              {moment
                .utc(
                  `${this.state.interviewDate} ${this.state.interviewTime}`,
                  "YYYY-MM-DD HH:mm:ss"
                )
                .tz(this.state.selectedTimezone)
                .format("MMMM Do")}
              &nbsp;at&nbsp;
              {moment
                .utc(
                  `${this.state.interviewDate} ${this.state.interviewTime}`,
                  "YYYY-MM-DD HH:mm:ss"
                )
                .tz(this.state.selectedTimezone)
                .format("hh:mm A")}
              &nbsp;({this.state.timezoneName}).
            </p>
          ),
          maskClosable: "true",
          width: "350px",
          maskStyle: { backgroundColor: "rgba(0, 39, 102, 0.7)" },
          className: "info_modal_normal custom_header_color",
          onOk() {},
        });
        this.props.onCompleted && this.props.onCompleted();
        break;
      case API_TIME_ZONE:
        this.setState({
          timezoneList: response.data.timezone,
        });
        break;
      default:
        break;
    }
  };

  onError = (response, reqId) => {
    switch (reqId) {
      case API_SEE_AVAILABILITY:
        notification.error({
          message: response.meta.message,
        });
        break;
        break;
      case API_SCHEDULE_INTERVIEW:
        notification.error({
          message: response.meta.message,
        });
        break;
      case API_TIME_ZONE:
        notification.error({
          message: response.meta.message,
        });
        break;
      default:
        break;
    }
  };

  checkSlot48Hr = (e) => {
    let slotDate = moment
      .utc(e.start_date_time, "YYYY-MM-DD HH:mm:ss")
      .tz(this.state.selectedTimezone)
      .format("MM/DD/YYYY hh:mm A");

    let currentDate = moment().add(48, "hours").format("MM/DD/YYYY hh:mm A");

    return moment(slotDate).isBefore(currentDate);
  };

  render() {
    const {
      visible1,
      dataAvailability,
      interviewTime,
      interviewDate,
      interviewNote,
      allocatedDateTimes,
      selectedSlot,
      interviewStaus,
      timezoneList,
      selectedTimezone,
      timezoneName,
      slotArray,
      dateArray,
    } = this.state;
    const { detail } = this.props;

    return (
      <>
        {detail.invitation.study_status === 0 ? (
          <>
            {detail.interview_type_id != 1 && (
              <Button
                className="link_button"
                type="primary"
                style={{ width: "auto" }}
                onClick={() =>
                  this.showModal(
                    detail,
                    dataAvailability,
                    detail.invitation.study_status,
                    detail.schedule_date &&
                      detail.schedule_date.timezone.timezone
                  )
                }
              >
                See Availability
              </Button>
            )}
          </>
        ) : (
          detail.invitation.study_status === 1 && (
            <>
              {(detail.schedule_date === null ||
                detail.schedule_date.status === 3) && (
                <Button
                  className="link_button"
                  type="primary"
                  style={{ width: "auto", margin: "0.5em" }}
                  onClick={() =>
                    this.showModal(
                      detail,
                      dataAvailability,
                      detail.invitation.study_status,
                      detail.schedule_date !== null &&
                        detail.schedule_date.timezone.timezone
                    )
                  }
                >
                  See Availability
                </Button>
              )}
            </>
          )
        )}

        {/* see availablity modal */}
        <Radio.Group onChange={this.onChange}>
          <Modal
            visible={this.state.visible}
            // onOk={this.handleOk}
            onCancel={this.handleCancel}
            width="80%"
            maskStyle={{ backgroundColor: "rgba(0, 39, 102, 0.7)" }}
            maskClosable={false}
            footer={null}
            centered
            className="radio_timezone"
          >
            <Row type="flex" gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
              <Col xs={{ span: 18 }} sm={{ span: 18 }} lg={{ span: 6 }}>
                <span className="text_app_color text_large">
                  Available Interview Times
                </span>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <DatePicker
                  onChange={this.onChangeDate}
                  placeholder="Search by date"
                  style={{ width: "100%" }}
                  format="MM/DD/YYYY"
                />
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 8 }}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Timezone"
                  optionFilterProp="children"
                  onChange={this.changeTimeZone}
                  // defaultValue={
                  //   interviewStaus === 1 || interviewStaus === 2
                  //     ? studyTimezone
                  //     : selectedTimezone
                  // }
                  defaultValue={selectedTimezone}
                >
                  {timezoneList.map((timezone, t_index) => (
                    <Option
                      value={timezone.timezone}
                      title={timezone.name}
                      index={t_index}
                    >
                      {timezone.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              {dateArray && (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  lg={{ span: 24 }}
                  style={{ paddingTop: "0" }}
                >
                  <span
                    className="text_app_color text_semibold"
                    style={{ marginRight: "5px" }}
                  >
                    Note:
                  </span>
                  <i className="text_app_color">
                    Please contact the project manager directly to inquire about
                    scheduling one of these gray timeslots.
                  </i>
                </Col>
              )}
            </Row>

            <Row
              className="bg_gray-1"
              type="flex"
              align="middle"
              style={{
                padding: "5px 0",
                marginBottom: "15px",
                marginTop: "10px",
              }}
            >
              <Col
                xs={{ span: 6 }}
                sm={{ span: 6 }}
                md={{ span: 6 }}
                lg={{ span: 3 }}
                className="text_center"
              >
                <Text className="text_app_color text_normal">Dates</Text>
              </Col>
              <Col
                xs={{ span: 18 }}
                sm={{ span: 18 }}
                md={{ span: 18 }}
                lg={{ span: 21 }}
              >
                <Text
                  className="text_app_color text_normal"
                  style={{ paddingLeft: "20px" }}
                >
                  Available times
                </Text>
              </Col>
            </Row>
            <div className="radio_timezone_body">
              {dateArray.map((date, date_index) => (
                <Row type="flex" key={{ date_index }}>
                  {moment
                    .tz(date, this.state.selectedTimezone)
                    .utc()
                    .diff(moment().utc().format("YYYY-MM-DD"), "d") >= 0 ? (
                    <>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 3 }}
                        className="text_center time_wrapper"
                      >
                        <span
                          className="text_app_color text_normal"
                          style={{ display: "block" }}
                        >
                          {moment(date, "YYYY-MM-DD").format("MM/DD/YYYY")}
                        </span>
                        <span className="text_gray-4 text_medium">
                          {moment(date, "YYYY-MM-DD").format("dddd")}
                        </span>
                      </Col>

                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 21 }}
                      >
                        <div className="btn_wrapper">
                          {slotArray[date].map((e) => (
                            <Radio.Button
                              checked={false}
                              value={`${e.id} ${moment
                                .utc(e.start_date_time, "YYYY-MM-DD HH:mm:ss")
                                .format("YYYY-MM-DD HH:mm:ss")}`}
                              disabled={
                                allocatedDateTimes.findIndex(
                                  (element) =>
                                    element.date_time ===
                                    moment
                                      .utc(
                                        e.start_date_time,
                                        "YYYY-MM-DD HH:mm:ss"
                                      )
                                      .format("YYYY-MM-DD HH:mm:ss")
                                ) !== -1 || this.checkSlot48Hr(e)
                              }
                            >
                              {moment
                                .utc(e.start_date_time, "YYYY-MM-DD HH:mm:ss")
                                .tz(this.state.selectedTimezone)
                                .format("hh:mm A")}
                            </Radio.Button>
                          ))}
                        </div>
                      </Col>
                    </>
                  ) : null}
                </Row>
              ))}
            </div>

            <Row>
              <Col span={24} className="radio_timezone_footer">
                {this.state.status === 1 ? (
                  <div>
                    {interviewStaus === 1 || interviewStaus === 2 ? (
                      <Button
                        className="link_button"
                        type="primary"
                        style={{ width: "auto" }}
                        onClick={() => this.showRescheduleModal()}
                        disabled={selectedSlot === null ? true : false}
                      >
                        Reschedule Interview
                      </Button>
                    ) : (
                      <Button
                        className="link_button"
                        type="primary"
                        style={{ width: "auto" }}
                        onClick={() =>
                          this.showModal1(
                            { uuid: this.state.studyId },
                            dataAvailability,
                            selectedSlot
                          )
                        }
                        disabled={selectedSlot === null ? true : false}
                      >
                        Schedule interview
                      </Button>
                    )}
                  </div>
                ) : (
                  <Button
                    className="link_button"
                    type="primary"
                    onClick={() => this.closeModal()}
                  >
                    Ok
                  </Button>
                )}
              </Col>
            </Row>
          </Modal>
        </Radio.Group>

        {/* schedule interview modal */}
        <Modal
          visible={visible1}
          onCancel={this.handleCancel}
          footer={null}
          maskStyle={{ backgroundColor: "rgba(0, 39, 102, 0.7)" }}
          maskClosable={false}
          centered
          title="Schedule an Interview"
          className="radio_timezone custom_header_color"
        >
          <Row
            type="flex"
            align="middle"
            gutter={[16, { xs: 8, sm: 16, md: 24, lg: 16 }]}
          >
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}>
              Interview Date:
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 17 }}>
              <p style={{ marginBottom: 0 }}>
                {interviewDate &&
                  interviewTime &&
                  moment
                    .utc(
                      `${interviewDate} ${interviewTime}`,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                    .tz(selectedTimezone)
                    .format("MM/DD/YYYY")}
              </p>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}>
              Timezone:
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 17 }}>
              <p style={{ marginBottom: 0 }}>{timezoneName}</p>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 7 }}>
              Interview Time:
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 17 }}>
              <p style={{ marginBottom: 0 }}>
                {interviewDate &&
                  interviewTime &&
                  moment
                    .utc(
                      `${interviewDate} ${interviewTime}`,
                      "YYYY-MM-DD HH:mm:ss"
                    )
                    .tz(selectedTimezone)
                    .format("hh:mm A")}
              </p>
            </Col>
          </Row>

          <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 16 }]}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }}>
              Add Additional note:
              <div>
                <TextArea
                  rows={3}
                  onChange={this.onChangeNote}
                  value={this.state.interviewNote}
                  placeholder="E. g. - call me at a different number than is in my profile, call my admin, call me with reminder 5 min before."
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  this.scheduleInterview(
                    interviewDate,
                    interviewTime,
                    interviewNote
                  )
                }
                // disabled={
                //   interviewNote && interviewNote.length <= 0 ? true : false
                // }
                style={{ marginRight: "10px" }}
              >
                Schedule
              </Button>

              <Button onClick={this.handleBack}>Interview Dates</Button>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Schedulebuttons);
