import React, { Component } from "react";
import RegisterHeader from "../components/register/RegisterHeader2";
import Footer from "../components/global/Footer";
import Stepper from "../components/register/Stepper";
import { Col, Row, Typography } from "antd";
import { Helmet } from "react-helmet";

const { Title, Text, Paragraph } = Typography;

class Register extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Register</title>
        </Helmet>
        <RegisterHeader />
        <Row type="flex" justify="center" className="custom_stepper_bar">
          <Col xs={22} sm={22} lg={15} xl={14}>
            <div style={{ textAlign: "center", marginTop: "2em" }}>
              <Title
                level={3}
                style={{ margin: "0.5em" }}
                className="text_bold header_font"
              >
                Let's Get Started
              </Title>
              
              {/* <Paragraph className="text_medium" style={{ color: "#6B6E71" }}>
              This information will help us to verify your account and ensure
              you are receiving market research invitations that are relevant to
              your specialty and practice setting. It should only take a few
              short minutes to complete.
            </Paragraph>
            <Paragraph className="text_medium" style={{ color: "#6B6E71" }}>
              After you’ve completed registering, we will follow up with a more
              comprehensive questionnaire that will allow us to match you with
              available studies with even greater precision. You can complete
              this additional information as time allows.
            </Paragraph>
            <Paragraph className="text_medium" style={{ color: "#6B6E71" }}>
              Thank you in advance and we look forward to welcoming you as the
              newest member of Sentiment.
            </Paragraph> */}
            </div>
            <Stepper {...this.props} />
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

export default Register;
