import React, { Component } from "react";
import FormData from "./FormData";
import { Form, Input, Radio, Typography, Checkbox, Select } from "antd";
import { connect } from "react-redux";

const { Title, Text } = Typography;
const { Option } = Select;

const radioStyle = {
  display: "block",
  minHeight: "30px",
  lineHeight: "30px",
  marginLeft: 0,
};

class Step4 extends Component {
  state = {
    contact_value: null,
    appointment_value: null,
    checkbox: false,
  };

  onChange = (e) => {
    this.setState({
      contact_value: e.target.value,
      checkbox: e.target.value === 2 ? true : false,
    });
  };

  onChangeAppointment = (e) => {
    this.setState({
      appointment_value: e.target.value,
      checkbox: e.target.value === 2 ? true : false,
    });
  };

  componentDidMount = () => {
    if (this.props.formFields?.fixed_field?.preference_appointment) {
      this.setState({
        appointment_value:
          this.props.formFields.fixed_field.preference_appointment,
      });
    }

    if (this.props.formFields?.fixed_field?.preference_contact) {
      this.setState({
        contact_value: this.props.formFields?.fixed_field?.preference_contact,
      });
    }
  };

  render() {
    const { form, data, formFields, formField, reference } = this.props;

    const prevData = formField[Object.keys(formField)[0]];
    const { getFieldDecorator } = form;
    const { contact_value, appointment_value } = this.state;

    return (
      <div className="register_stepper_form">
        <Title className="text_app_color_light" level={4}>
          {data.title}
        </Title>

        {/* dynamic question start */}
        <FormData form={form} data={data} formFields={formFields} />
        {/* dynamic question end */}

        <Form.Item
          label={<span className="text_semibold">Preference for contact</span>}
          labelCol={{ span: 24 }}
        >
          {getFieldDecorator(`[fixed_field]preference_contact`, {
            initialValue:
              formFields && formFields.fixed_field
                ? formFields.fixed_field.preference_contact
                : [],
            rules: [
              {
                required: true,
                message: "This Field is Required!",
              },
            ],
          })(
            <Radio.Group
              onChange={this.onChange}
              value={contact_value}
              style={{ width: "100%" }}
            >
              <Radio style={radioStyle} value={1}>
                Phone Calls
              </Radio>
              {contact_value === 1 ? (
                <Form.Item>
                  {getFieldDecorator(`[fixed_field]contact_phoneno`, {
                    initialValue: formFields?.fixed_field?.contact_phoneno
                      ? formFields?.fixed_field?.contact_phoneno
                      : prevData && prevData.fixed_field
                        ? prevData.fixed_field.phoneno
                        : [],
                    rules: [
                      { required: true, message: "This Field is Required!" },
                      {
                        pattern: /^(\s*|\d{10})$/,
                        message:
                          "The phone number should be 10-digits, including the area code.",
                      },
                    ],
                  })(
                    <Input
                      addonBefore={"+1"}
                      className="field_custome_hight"
                      placeholder={"Enter Phone Number"}
                    />
                  )}
                </Form.Item>
              ) : null}

              <Radio style={radioStyle} value={2}>
                Text Message
              </Radio>
              {contact_value === 2 ? (
                <Form.Item>
                  {getFieldDecorator(`[fixed_field]contact_phoneno`, {
                    initialValue: formFields?.fixed_field?.contact_phoneno
                      ? formFields?.fixed_field?.contact_phoneno
                      : prevData && prevData.fixed_field
                        ? prevData.fixed_field.phoneno
                        : [],
                    rules: [
                      { required: true, message: "This Field is Required!" },
                      {
                        pattern: /^(\s*|\d{10})$/,
                        message:
                          "The phone number should be 10-digits, including the area code.",
                      },
                    ],
                  })(
                    <Input
                      addonBefore={"+1"}
                      className="field_custome_hight"
                      placeholder={"Enter Phone Number"}
                    />
                  )}
                </Form.Item>
              ) : null}

              <Radio style={radioStyle} value={3}>
                Email
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item
          label={
            <span className="text_semibold">
              Preference for Appointment Reminders
            </span>
          }
          labelCol={{ span: 24 }}
        >
          {getFieldDecorator(`[fixed_field]preference_appointment`, {
            initialValue:
              formFields && formFields.fixed_field
                ? formFields.fixed_field.preference_appointment
                : [],
            rules: [
              {
                required: true,
                message: "This Field is Required!",
              },
            ],
          })(
            <Radio.Group
              onChange={this.onChangeAppointment}
              value={contact_value}
              style={{ width: "100%" }}
            >
              <Radio style={radioStyle} value={1}>
                Phone Calls
              </Radio>

              {appointment_value === 1 ? (
                <Form.Item>
                  {getFieldDecorator(`[fixed_field]appointment_phoneno`, {
                    initialValue: formFields?.fixed_field?.appointment_phoneno
                      ? formFields?.fixed_field?.appointment_phoneno
                      : prevData && prevData.fixed_field
                        ? prevData.fixed_field.phoneno
                        : [],
                    rules: [
                      { required: true, message: "This Field is Required!" },
                      {
                        pattern: /^(\s*|\d{10})$/,
                        message:
                          "The phone number should be 10-digits, including the area code.",
                      },
                    ],
                  })(
                    <Input
                      addonBefore={"+1"}
                      className="field_custome_hight"
                      placeholder={"Enter Phone Number"}
                    />
                  )}
                </Form.Item>
              ) : null}

              <Radio style={radioStyle} value={2}>
                Text Message
              </Radio>
              {appointment_value === 2 ? (
                <Form.Item>
                  {getFieldDecorator(`[fixed_field]appointment_phoneno`, {
                    initialValue: formFields?.fixed_field?.appointment_phoneno
                      ? formFields?.fixed_field?.appointment_phoneno
                      : prevData && prevData.fixed_field
                        ? prevData.fixed_field.phoneno
                        : [],
                    rules: [
                      { required: true, message: "This Field is Required!" },
                      {
                        pattern: /^(\s*|\d{10})$/,
                        message:
                          "The phone number should be 10-digits, including the area code.",
                      },
                    ],
                  })(
                    <Input
                      addonBefore={"+1"}
                      className="field_custome_hight"
                      placeholder={"Enter Phone Number"}
                    />
                  )}
                </Form.Item>
              ) : null}

              <Radio style={radioStyle} value={3}>
                Email
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label={<span className="text_semibold">SMS Opt-in</span>}>
          {getFieldDecorator("[fixed_field]notification_text", {
            valuePropName: "notification_text",
            rules: [
              {
                required:
                  this.state.appointment_value === 2 ||
                    this.state.contact_value === 2
                    ? true
                    : false,

                message:
                  "You’ve selected Text Message as one of your preferred methods of contact. Please check this box to provide permission for Sentiment to send you text messages.",
              },
            ],
          })(
            <Checkbox.Group>
              <Checkbox>
                By checking this box, you agree to accept text messages from
                Sentiment including new study invitations, appointment
                confirmations, payment status updates, and other research
                related communications. We will never sell this information or
                provide access to a third party.
              </Checkbox>
            </Checkbox.Group>

            // <Checkbox>
            //   I agree to receive text messages from Trinity regarding available
            //   studies and other research-related matters.
            // </Checkbox>
          )}
        </Form.Item>

        <Form.Item
          label={
            <span className="text_semibold">How did you hear about us?</span>
          }
        >
          {getFieldDecorator("[fixed_field]user_reference", {
            initialValue:
              formFields && formFields.fixed_field
                ? formFields.fixed_field.user_reference
                : [],
            rules: [{ required: true, message: "This Field is Required!" }],
          })(
            <Select
              className="field_custome_hight"
              showSearch
              placeholder="Select"
              optionFilterProp="children"
            >
              {
                (reference && reference.length > 0) && reference.map(e => <Option value={e.id}>{e.name}</Option>)
              }
            </Select>
          )}
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
          }}
        >
          <Text className="text_medium" style={{ color: "#6B6E71" }}>
            For questions or support please contact -
            support@sentimentglobal.com
          </Text>
        </div>
      </div>
    );
  }
}

const WrappedStep4 = Step4;

const mapStateToProps = (state) => {
  return {
    formField: state.formField,
  };
};

export default connect(mapStateToProps)(WrappedStep4);
