import { useEffect } from "react";

export const SignIn = () => {
  useEffect(() => {
    window.location.replace(`https://app.sentimentglobal.com/login`);
  }, []);

  return null;
}

export default SignIn;