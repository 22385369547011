/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Affix, Typography } from "antd";
import { Link, Redirect } from "react-router-dom";
import SignInBg from "../assets/images/landing/bg1.jpg";
import SignInBg2 from "../assets/images/landing/bg2.jpg";

import DivImg1 from "../assets/images/landing/div1.jpg";
import DivImg2 from "../assets/images/landing/div2.jpg";
import DivImg3 from "../assets/images/landing/div3.jpg";
import DivImg4 from "../assets/images/landing/div4.jpg";
import DivImg5 from "../assets/images/landing/div5.jpg";
import DivImg6 from "../assets/images/landing/div6.jpg";

import Icon1 from "../assets/images/landing/1.svg";
import Icon2 from "../assets/images/landing/2.svg";
import Icon3 from "../assets/images/landing/3.svg";
import Icon4 from "../assets/images/landing/4.svg";

import sec1 from "../assets/images/landing/sec1.svg";
import sec2 from "../assets/images/landing/sec2.svg";

import Header from "../components/global/CoverHeader";
import Footer from "../components/global/CoverFooter";
import { connect } from "react-redux";
import "../scss/coverPage.scss";

const { Title, Text } = Typography;

const CoverPage = (props) => {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    props.user !== null ? setIsLogin(true) : setIsLogin(false);
  });

  const cardData = [
    {
      img: DivImg1,
      title: "FAST AND FLEXIBLE INCENTIVE OPTIONS",
      discription:
        "Our studies pay very competitive, fair market rates, and we process honoraria payments almost immediately. With our new payment portal, you have the option to choose one of our fast and flexible payment methods or a standard check, whichever you prefer. Our user-friendly website has all of your project and payment information available at your fingertips.",
    },
    {
      img: DivImg2,
      title: "RESEARCH TAILORED TO YOUR AREA OF EXPERTISE",
      discription:
        "We won't overwhelm you with invitations to studies you don't qualify for. We take a much more thoughtful approach with our enhanced profiling process and will only target you for studies we feel are appropriate. Shortly after registering, we'll invite you to complete a more comprehensive profile about your practice and patient population, so we can avoid the need for lengthy screeners.",
    },
    {
      img: DivImg3,
      title: "BESPOKE, PERSONAL SUPPORT TEAM",
      discription:
        "Our dedicated team is here to provide you with timely and personalized support. You won't be waiting days for a response to your message, and you'll likely get to know our research team by name. At Sentiment, we are committed to building relationships with our advisors.",
    },
  ];

  const iconData = [
    {
      icon: Icon2,
      title1: "OVER",
      title2: "STUDIES COMPLETED",
      count: "400",
    },
    {
      icon: Icon3,
      title1: "MORE THAN",
      title2: "RESPONDENTS",
      count: "68,000",
    },
    {
      icon: Icon4,
      title1: "NEARLY",
      title2: "CLIENTS SERVED",
      count: "300",
    },
  ];

  return isLogin ? (
    <Redirect to="/home" />
  ) : (
    <div>
      <Affix offsetTop={0}>
        <Header />
      </Affix>

      <Row
        type="flex"
        justify="end"
        style={{
          minHeight: "90vh",
          backgroundImage: `linear-gradient(0deg, rgba(52, 99, 147, 0.45), rgba(52, 99, 147, 0.45)),url(${SignInBg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Col
          xs={{ span: "20", pull: "2" }}
          sm={{ span: "18", pull: "3" }}
          lg={{ span: "12", pull: "1" }}
          xl={{ span: "8", pull: "1" }}
          className="cover_sec"
        >
          <div className="bg_white cover_div_radius">
            <Text className="text_left text_bold text_app_secondary_color title_2 header_font">
              Join a Research Program That Treats You Like a Partner, Not a
              Respondent.
            </Text>
            <br />
            <br />

            <div>
              <h4>
                When you work with Sentiment, you are working directly with our
                dedicated team on research that is strategically vital to the
                life sciences industry. Because we oversee every aspect of the
                engagement, we can offer you an exceptional, high-touch
                experience. You’ll never feel like “just another number” in our
                database.
              </h4>
              <br />
              <h4>
                We respect your time and expertise, will compensate you fairly,
                and provide personalized service. That’s our commitment to you.
              </h4>
              <br />
              <h4 className="text_bold">
                Register today and help us to continue elevating healthcare
                market research.
              </h4>
              <br />
            </div>
            <div>
              <Button
                className="cover_button_outline"
                type="primary"
                size="large"
                style={{ margin: "0 10px 10px 0" }}
              >
                {/* <Link to="/sign_in" className="text_semibold">
                  LOG IN
                </Link> */}
                <a className="text_semibold" href="https://app.sentimentglobal.com/login">
                  LOG IN
                </a>
              </Button>
              <Button className="cover_button" type="primary" size="large">
                {/* <Link to="/register" className="text_semibold">
                  REGISTER
                </Link> */}
                <a className="text_semibold" href="https://app.sentimentglobal.com/signup">
                  REGISTER
                </a>
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Row type="flex" justify="center" style={{ padding: "2.5em 0" }}>
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          lg={{ span: "24" }}
          xl={{ span: "18" }}
        >
          <Row type="flex" justify="center">
            <Col
              xs={{ span: "22" }}
              sm={{ span: "22" }}
              lg={{ span: "22" }}
              xl={{ span: "12" }}
            >
              <div style={{ marginBottom: "2.5em" }}>
                <Title
                  level={3}
                  className="text_center text_bold text_app_secondary_color header_font title_4"
                >
                  We Know Your Time Is Valuable and You’ll Feel That From Us at
                  Every Touch-Point.
                </Title>
              </div>
            </Col>
            <Col span={24}></Col>

            {cardData.map((e) => (
              <Col
                xs={{ span: "22" }}
                sm={{ span: "22" }}
                lg={{ span: "22" }}
                xl={{ span: "8" }}
                style={{ padding: "2em", paddingTop: 0 }}
              >
                <div className="text_center">
                  <img src={e.img} style={{ width: "100%" }} alt="logo" />
                  <div>
                    <Title
                      level={4}
                      className="text_center text_semibold text_app_secondary_color header_font"
                      style={{ margin: "0.5em" }}
                    >
                      {e.title}
                    </Title>
                    <p>{e.discription}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="center"
        style={{
          minHeight: "70vh",
          backgroundImage: `linear-gradient(0deg, rgba(52, 99, 147, 0.45), rgba(52, 99, 147, 0.45)),url(${SignInBg2})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Col
          xs={{ span: "20" }}
          sm={{ span: "20" }}
          lg={{ span: "18" }}
          xl={{ span: "15" }}
          className="cover_sec"
        >
          <div className="bg_white cover_div_radius" style={{ width: "100%" }}>
            <Row className="div_text_center">
              <Col
                xs={{ span: "24" }}
                sm={{ span: "24" }}
                lg={{ span: "8" }}
                xl={{ span: "8" }}
              >
                <div>
                  <img src={DivImg6} alt="" height="auto" width="90%" />
                </div>
              </Col>
              <Col
                xs={{ span: "24" }}
                sm={{ span: "24" }}
                lg={{ span: "16" }}
                xl={{ span: "16" }}
              >
                <div>
                  <Title
                    level={3}
                    className="text_bold text_app_secondary_color header_font how_text"
                  >
                    HOW WE'RE DIFFERENT
                  </Title>
                  <div className="border_bottom_left"></div>
                  <div className="border_bottom"></div>
                </div>
                <div className="content_sec">
                  <Text
                    className=" text_semibold text_text_dark title_4 header_font"
                    style={{ display: "flex" }}
                  >
                    Unlike traditional panel companies, Sentiment is focused
                    exclusively on facilitating our own research engagements.
                  </Text>
                  <br />
                  <Text
                    className=" text_semibold text_text_dark title_4 header_font"
                    style={{ display: "flex" }}
                  >
                    We value quality over quantity, always offer competitive
                    honorarium amounts, and will never sell your information to
                    a third party.
                  </Text>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="center"
        style={{ minHeight: "70vh" }}
        className="bg_app_secondary_color"
      >
        <Col
          xs={{ span: "20" }}
          sm={{ span: "18" }}
          lg={{ span: "18" }}
          xl={{ span: "12" }}
          className="cover_sec_quote"
        >
          <div className="main_sec">
            <div className="icon_div">
              <img src={Icon1} />
            </div>
            <h3 className="text_center text_semibold text_white header_font">
              "A company that truly values my time, recognizes the true value of
              my opinion and recognizes me as a person.
            </h3>
            <br />
            <h3 className="text_center text_semibold text_white header_font">
              I feel the “personal touch” unlike other market research
              companies. Always respond promptly to my questions, by a “member
              of the team” directly instead of sending to a “customer support”
              that responds with a template message.
            </h3>
            <br />
            <h3 className="text_center text_semibold text_white header_font">
              Surveys are easy to take, appropriate to my specialty and don’t
              spam my account with “underpaid” survey requests."
            </h3>
            <br />

            <h3 className="text_center text_white header_font">
              DR. PAVAN BHAMIDIPATI
            </h3>
          </div>
        </Col>
      </Row>

      <Row
        type="flex"
        justify="space-around"
        className="bg_white img_container"
      >
        <Col
          xs={{ span: "20" }}
          sm={{ span: "18" }}
          lg={{ span: "12" }}
          xl={{ span: "10" }}
        >
          <img src={sec1} alt="" style={{ width: "100%" }} />
        </Col>
        <Col
          xs={{ span: "20" }}
          sm={{ span: "18" }}
          lg={{ span: "12" }}
          xl={{ span: "10" }}
        >
          <img src={sec2} alt="" style={{ width: "100%" }} />
        </Col>
      </Row>

      <Row
        type="flex"
        justify="space-between"
        className="bg_app_color text_white"
      >
        <Col
          xs={{ span: "20", offset: "2" }}
          sm={{ span: "18" }}
          lg={{ span: "12" }}
          xl={{ span: "9", offset: "2" }}
          className="bg_app_color"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ margin: "20px" }}>
            <Text className="text_left text_bold text_white title_2 header_font">
              We're always looking to add expertise to Sentiment
            </Text>
            <p
              className="text_left text_semibold text_white"
              style={{ margin: "2em 0" }}
            >
              Join us today and help us elevate life <br />
              sciences market research!
            </p>
            <Button
              className="cover_button text_semibold"
              size="large"
              type="primary"
            >
              <Link to="/register">REGISTER</Link>
            </Button>
          </div>
        </Col>
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          lg={{ span: "12" }}
          xl={{ span: "12" }}
        >
          <img src={DivImg4} alt="" style={{ width: "100%" }} />
        </Col>
      </Row>

      <Row
        type="flex"
        justify="start"
        className="bg_app_secondary_color text_white"
      >
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          lg={{ span: "12" }}
          xl={{ span: "12" }}
          order={1}
        >
          <img src={DivImg5} alt="" style={{ width: "100%" }} />
        </Col>
        <Col
          xs={{ span: "20", offset: "2" }}
          sm={{ span: "18" }}
          lg={{ span: "12" }}
          xl={{ span: "9", offset: "2" }}
          order={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ margin: "20px" }}>
            <Text className="text_left text_bold text_white title_2 header_font">
              Learn more about our parent company, Trinity Life Sciences
            </Text>
            <p
              className="text_left text_semibold text_white"
              style={{ margin: "2em 0" }}
            >
              Originally founded in 1996 as Trinity Partners, Trinity has since
              grown to comprise a global team of advisors, technical experts,
              data integrators, and technology innovators.
            </p>
            <Button
              className="cover_button text_semibold"
              size="large"
              type="primary"
            >
              <a href="https://trinitylifesciences.com/" target="_blank">
                LEARN MORE
              </a>
            </Button>
          </div>
        </Col>
      </Row>

      <Row type="flex" justify="center" className="bg_white main_div_sec">
        <Col
          xs={{ span: "24" }}
          sm={{ span: "24" }}
          lg={{ span: "24" }}
          xl={{ span: "24" }}
        >
          <div className="text_center">
            <Text className="text_center text_bold title_2 header_font">
              We've been in business for 25 years.
            </Text>
            <div className="cover_div">
              <span className="text_center cover_label text_bold header_font">
                THIS YEAR ALONE, WE EXPECT TO HAVE:
              </span>
            </div>
          </div>
        </Col>

        {iconData.map((e) => (
          <Col
            xs={{ span: "24" }}
            sm={{ span: "8" }}
            lg={{ span: "5" }}
            xl={{ span: "5" }}
            className="text_center"
            style={{ marginTop: "1em" }}
          >
            <div>
              <img src={e.icon} height="120px" />
            </div>
            <div className="text_center" style={{ marginTop: "1em" }}>
              <span className="text_semibold header_font">{e.title1}</span>
              <h1 className="count_txt header_font">{e.count}</h1>
              <span className="text_semibold header_font">{e.title2}</span>
            </div>
          </Col>
        ))}
      </Row>

      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(CoverPage);
