import React from "react";

const Page404 = () => {
  return (
    <div>
      <h1>404 - Not found</h1>
    </div>
  );
};

export default Page404;
