import { APIRequest } from "./api-request";
export const API_REGISTER_SUBMIT = 1;
export const API_LOGIN_SUBMIT = 2;
export const API_REGISTER_QUESTION = 3;
export const API_FORGOT_PASSWORD = 4;
export const API_STORE_REGISTER = 5;
export const API_EMAIL_CHECK = 6;
export const API_HOME = 7;
export const API_PENDING_STUDY = 8;
export const API_CURRENT_STUDY = 9;
export const API_PAST_STUDY = 10;
export const API_STUDY_DETAIL = 11;
export const API_SEE_AVAILABILITY = 12;
export const API_ADD_REVIEW = 13;
export const API_SCHEDULE_INTERVIEW = 14;
export const API_CANCEL_INTERVIEW = 15;
export const API_RESCHEDULE_INTERVIEW = 16;
export const API_MY_APPOINTMENTS = 17;
export const API_TIME_ZONE = 18;
export const API_NOTIFICATIONS = 19;
export const API_GET_CONTENT = 20;
export const API_PAYMENT = 21;
export const API_CHANGE_PASSWORD = 22;
export const API_VIEW_PROFILE = 23;
export const API_EDIT_PROFILE = 24;
export const API_UPDATE_PROFILE = 25;
export const API_NOTIFICATIONS_PREF = 26;
export const API_GOOGLE_SYNC_ON = 27;
export const API_GOOGLE_SYNC_OFF = 28;
export const API_ADOBE_SIGN_RESPONSE = 29;
export const API_USER_ROLE_FETCH = 30;
export const API_RESEND_VERIFY_MAIL = 31;
export const API_GET_STATES = 32;
export const API_GET_PHONE_OPTIONS = 33;
export const API_UPDATE_SURVEY_STATUS = 34;

export { APIRequest };