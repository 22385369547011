import React, { Component } from "react";
import Header from "../components/global/Header";
import RegisterHeader from "../components/register/RegisterHeader2";
import Footer from "../components/global/CoverFooter";
import { Row, Col, Typography, Collapse, Icon } from "antd";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

const { Text, Title } = Typography;
const { Panel } = Collapse;

const customPanelStyle = {
  background: "white",
  border: "0px solid #e7e6e6",
  overflow: "hidden",
  paddingTop: "2.5em",
};

const data = [
  {
    title: "What is Sentiment?",
    description:
      "Sentiment is a healthcare advisory research panel owned by Trinity Life Sciences. Originally founded in 1996 as Trinity Partners, Trinity has since grown to comprise a global team of advisors, technical experts, data integrators, and technology innovators. Our track record of consistent growth for over two decades has been fueled by our commitment to our clients, our passion for the life sciences, and our depth of expertise in the industry. Since the beginning, we have been evidence-forward in all our analyses and recommendations, focused on quality and accuracy first and foremost.",
  },
  {
    title: "Who can join Sentiment?",
    description:
      "Sentiment is currently comprised mostly of physicians, nurses, nurse practitioners, physician assistants, and pharmacists. We are actively seeking patients, practice managers, reimbursement specialists, healthcare executives, and other allied health professionals to join our growing healthcare research panel.",
  },

  {
    title:
      "I’m already a part of other research panels. What makes Sentiment different?",
    description:
      "Sentiment is not like other panel companies. We do not sell access to our panel to third parties – we only service our own research engagements. This means you’ll get a more personalized experience when working with us we’ll work tirelessly to be efficient with the time you give us.  You’ll never have to worry about your inbox being flooded with underpaid survey opportunities or invitations for studies you don’t qualify for. We are a boutique, flexible panel that values quality over quantity.",
  },

  {
    title: "What types of studies do you offer?",
    description:
      "Sentiment offers a variety of research studies including online surveys, telephone interviews, web-assisted interviews, and online and in-person interviews and groups.",
  },

  {
    title: "How many invitations will I receive?",
    description:
      "The frequency with which you are invited to participate in studies may vary. One thing we can promise is that we will never overwhelm you with invitations for studies you don't qualify for - our top priority is respecting your time and offering you opportunities that we feel are appropriate for your specialty and therapeutic area of expertise. ",
  },

  {
    title: "Am I expected to participate in every study I am invited to?",
    description:
      "You are free to participate as little or as often as you wish. We hope you will find our opportunities engaging and thought-provoking and will want to participate on a regular basis, however it's entirely up to you.",
  },
  {
    title: "How much will I earn on each study?",
    description:
      "Honorarium amounts will vary based on several factors but will always be competitive and in line with Fair Market Value. ",
  },
  {
    title: "How will I be paid?",
    description:
      "Sentiment offers fast and flexible payment options, including direct to debit, virtual and physical gift cards, or standard check.",
  },
  {
    title:
      "Can I donate my compensation to another person or a cause I support?",
    description:
      "At this time, payments may only be made directly to you and in your name.",
  },
  {
    title:
      "Who do I contact if I need help participating in a study or have a question about a payment?",
    description:
      "For questions or support please contact our helpful team at support@sentimentglobal.com.",
  },
  {
    title:
      "Can I forward study invitations to colleagues or friends who may be interested?",
    description:
      "Our invitations are only intended to be accessed by you, the recipient.  If you have a colleague interested in participating in research opportunities, we invite you to suggest they register with our panel by visiting this website www.sentimentglobal.com to sign up.",
  },
  {
    title: "Are my responses anonymous?",
    description:
      "Yes, all responses are anonymous unless explicitly stated and agreed to by all parties ahead of time.  Quantitative survey responses are reported in the aggregate. The privacy of our members is paramount, and we would never share your information with a third party without your express permission.",
  },
  {
    title: "What is your privacy policy?",
    description:
      `You can read our privacy policy by clicking on the following link: ${window.location.origin}/privacy`,
  },
  {
    title: "How can I update my profile?",
    description:
      "Once you’ve logged into your account, please proceed to the “My Account” page and click on “Edit Profile”.",
  },
  {
    title: "How can I unsubscribe from future study invitations?",
    description:
      "To unsubscribe from all future study invitations, please contact our team at support@sentimentglobal.com  for assistance. If you would like to discontinue receiving only phone calls or texts, we can assist with that as well.",
  },
];

export class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Frequently Asked Questions</title>
        </Helmet>
        {this.props.user ? <Header /> : <RegisterHeader />}
        <Row
          type="flex"
          justify="center"
          className="container_padding min-height-cal-regular"
          style={{ paddingBottom: "2em" }}
        >
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 18 }}
          >
            <Row type="flex" justify="center">
              <Col span={24} style={{ padding: "1em" }} className="faq_collapse">
                <div style={{ padding: "1.5em" }}>
                  <Title
                    level={2}
                    className="text_app_secondary_color text_semibold header_font text_center"
                  >
                    FREQUENTLY ASKED QUESTIONS
                  </Title>
                  <div className="border_bottom"></div>
                </div>
                <Collapse
                  defaultActiveKey={[0]}
                  expandIconPosition="right"
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <div className="cover_icon">
                        <Icon
                          type="minus"
                          className="text_large"
                          style={{ height: "1em", width: "1em" }}
                        />
                      </div>
                    ) : (
                      <div className="cover_icon">
                        <Icon
                          type="plus"
                          className="text_large"
                          style={{ height: "1em", width: "1em" }}
                        />
                      </div>
                    )
                  }
                  style={customPanelStyle}
                >
                  {data.map((e, key) => (
                    <Panel
                      header={
                        <Title
                          level={4}
                          className="text_semibold"
                          style={{ marginBottom: "0px", padding: "16px" }}
                        >
                          {e.title}
                        </Title>
                      }
                      key={key}
                      style={{
                        border: "2px solid #e7e6e6",
                        marginBottom: "2em",
                      }}
                    >
                      <Row>
                        <Col className="gutter-row" span={24}>
                          <p style={{ paddingLeft: "1em" }}>{e.description}</p>
                        </Col>
                      </Row>
                    </Panel>
                  ))}
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(Faqs);
