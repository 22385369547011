import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "antd";
import Header from "../components/global/Header";
import RegisterHeader from "../components/register/RegisterHeader2";
import Footer from "../components/global/Footer";
import CoverFooter from "../components/global/CoverFooter";
import { connect } from "react-redux";

export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Contact Us</title>
        </Helmet>
        {this.props.user ? <Header /> : <RegisterHeader />}
        <Row
          className="bg_app_color map-background"
          type="flex"
          justify="center"
          align="middle"
        >
          <Col span={22} style={{ height: "100%" }}>
            <p className="text_white mb-0" style={{ fontSize: "48px" }}>
              Get in Touch
            </p>
          </Col>
        </Row>
        <Row type="flex" justify="center" style={{ padding: "3em 0em" }}>
          {/* <Col
          xs={{ span: 22 }}
          sm={{ span: 22 }}
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className="contact_info_class"
        >
          <div style={{ display: "flex" }}>
            <div style={{ marginTop: "0.7em" }}>
              <i
                className="icon-call-2 text_app_color_light"
                style={{ fontSize: "32px", marginRight: "0.3em" }}
              />
            </div>
            <div>
              <p
                className="text_xlarge text_gray-4 text_semibold mb-0"
                style={{ margin: "0.7em 0" }}
              >
                Call us
              </p>
              <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                Office Phone: 609-613-3272
              </p>
              <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                Office Phone: 111-222-3333
              </p>
            </div>
          </div>
        </Col> */}
          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginTop: "0.7em" }}>
                <i
                  className="icon-location text_app_color_light"
                  style={{ fontSize: "32px", marginRight: "0.3em" }}
                />
              </div>
              <div>
                <p
                  className="text_xlarge text_gray-4 text_semibold mb-0"
                  style={{ margin: "0.7em 0" }}
                >
                  Where to find us
                </p>
              </div>
            </div>
          </Col>

          <Col
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            md={{ span: 12 }}
            lg={{ span: 10 }}
          >
            <Row>
              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 12 }}
                lg={{ span: 10, offset: 2 }}
                
                className="contact_info_class">
                <div>
                  <p
                    className="text_large text_gray-4 text_semibold mb-0"
                    style={{ margin: "0.5em 0" }}
                  >
                    Trinity Corporate Headquarters - USA
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    230 Third Avenue
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    5th Floor
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Waltham, MA 02451-7528
                  </p>
                </div>
              </Col>

              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 10, offset: 2 }}
                lg={{ span: 10, offset: 2 }}
              >
                <div>
                  <p
                    className="text_large text_gray-4 text_semibold mb-0"
                    style={{ margin: "0.5em 0" }}
                  >
                    Trinity EU
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Widenmayerstraße 50
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    80538 Munich, Germany
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{height: "20px"}} />

          <Col
            xs={{ span: 20 }}
            sm={{ span: 20 }}
            md={{ span: 12 }}
            lg={{ span: 10 }}
          >
            <Row>
              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 12 }}
                lg={{ span: 10, offset: 2 }}
                className="contact_info_class"
              >
                <div>
                  <p
                    className="text_large text_gray-4 text_semibold mb-0"
                    style={{ margin: "0.5em 0" }}
                  >
                    Trinity India
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Global Business Park,
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Tower – D, 8th Floor,
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Sikanderpur, Mehrauli Gurgaon Road,
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Gurgaon – 122002
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Haryana, India
                  </p>
                </div>
              </Col>

              <Col
                xs={{ span: 22 }}
                sm={{ span: 22 }}
                md={{ span: 10, offset: 2 }}
                lg={{ span: 10, offset: 2 }}
              >
                <div>
                  <p
                    className="text_large text_gray-4 text_semibold mb-0"
                    style={{ margin: "0.5em 0" }}
                  >
                    Trinity London
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    40 Basinghall Street
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    Twelfth Floor
                  </p>
                  <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                    London EC2V 5DE, UK
                  </p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginTop: "0.7em" }}>
                <i
                  className="icon-mail text_app_color_light"
                  style={{ fontSize: "32px", marginRight: "0.3em" }}
                />
              </div>
              <div>
                <p
                  className="text_xlarge text_gray-4 text_semibold mb-0"
                  style={{ margin: "0.7em 0" }}
                >
                  Write to us
                </p>
              </div>
            </div>
          </Col>

          <Col
            xs={{ span: 22 }}
            sm={{ span: 22 }}
            md={{ span: 22 }}
            lg={{ span: 22 }}
          >
            <div className="text_center">
              <p style={{ color: "#6B6E71", marginBottom: "0.5em" }}>
                support@sentimentglobal.com
              </p>
            </div>
          </Col>
        </Row>
        {this.props.user ? <Footer /> : <CoverFooter />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(ContactUs);
