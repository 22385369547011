import React, { Component } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

class CoverFooter extends Component {

  render() {
    return (
      <Row
        type="flex"
        align="middle"
        justify="center"
        className="cover_footer"
        style={{ padding: "20px" }}
      >
        <Col lg={{ span: 10, push: 2 }}>
          <Row type="flex" className="footer_class">
            {/* <Col className="footer_element">
              <h5>
                <Link to="/support">Support</Link>
              </h5>
            </Col>
            <Col className="footer_element">
              <h5>
                <Link to="/help-center">Help Center</Link>
              </h5>
            </Col> */}
            <Col className="footer_element">
              <h5>
                <Link to="/privacy" className="text_white text_regular header_font">PRIVACY POLICY</Link>
              </h5>
            </Col>
            <Col className="footer_element">
              <h5>
                <Link to="/terms-of-service" className="text_white text_regular  header_font">TERMS OF USE</Link>
              </h5>
            </Col>
          </Row>
        </Col>

        <Col lg={{ span: 14, pull: 1 }}>
          <h5 className="footer_copyright text_gray-2 text_regular header_font">
            <span className="text_normal header_font">&copy;&nbsp;</span>
            {new Date().getFullYear()} Trinity. All Rights Reserved.
          </h5>
        </Col>
      </Row>
    );
  }
}

export default CoverFooter;
