import React, { Component } from "react";
import {
  Typography,
  Form,
  Input,
  Select,
  DatePicker,
  Spin,
  Divider,
  Tooltip
} from "antd";
import { connect } from "react-redux";
import FormData from "./FormData";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;
const { MonthPicker } = DatePicker;

class Step1 extends Component {
  state = {
    confirmDirty: false,
    first_name: "",
    last_name: "",
    email_address: "",
    paypal_req: "",
    selectedFile: null,
    selectedFileList: [],

    payment:
      this.props.formFields && this.props.formFields.fixed_field
        ? this.props.formFields.fixed_field.Payment_preference
        : 0,
  };

  // password validation
  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  // password validation
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("[fixed_field]password")) {
      callback("The password and confirm password must match.");
    } else {
      callback();
    }
  };

  componentDidMount() {
    this.props.formFields.length !== 0 &&
      this.setState({
        user_role: this.props.formFields.fixed_field.user_role,
      });
  }

  // password validation
  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["[fixed_field]confirm"], { force: true });
    }
    callback();
  };

  // onChangePaypalEmail = e => {
  //   this.setState({paypal_req : e.target.value })
  // }

  // onChangePaypalId = e => {
  //   this.setState({paypal_req : e.target.value })
  // }

  // auto capitalize first name
  onChangeFirstName = (e) => {
    this.setState({
      first_name:
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
    });
  };

  // auto capitalize last name
  onChangeLastName = (e) => {
    this.setState({
      last_name:
        e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
    });
  };

  onChangeEmail = (e) => {
    this.setState({ email_address: e.target.value });
  };
  onChangePhone = (e) => {
    this.setState({ phoneno: e.target.value });
  };

  onChangeAlterNativePhone = (e) => {
    this.setState({ alternativePhoneno: e.target.value });
  };

  handlePaymentChange = (value) => {
    this.setState({ payment: value });
  };

  clearValue = () => {
    this.setState({
      time: null,
    });
  };

  disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  handleOpenChange = (status) => {
    if (status) {
      this.setState({ isopen: true });
    } else {
      this.setState({ isopen: false });
    }
  };

  handlePanelChange = (value) => {
    const { form } = this.props;

    this.setState(
      {
        selectedyear: value,
        isopen: false,
      },
      () => {
        form.setFieldsValue({
          "[fixed_field]experience_year": value.utc(),
        });
      }
    );
  };

  roleChange = (role_id) => {
    this.setState({
      user_role: role_id,
    });
    this.props.onRoleChange && this.props.onRoleChange(role_id);
  };

  render() {
    const { user_role } = this.state;
    const {
      form,
      data,
      timezone,
      speciality,
      formFields,
      userRole,
      state,
      phoneOptions,
    } = this.props;

    const { getFieldDecorator } = form;
    return (
      <div>
        {data !== undefined ? (
          <div className="register_stepper_form">
            <Title className="text_app_color_light" level={4}>
              {data.title}
            </Title>

            <div>
              <Form.Item
                label={<span className="text_semibold">First Name</span>}
              >
                {getFieldDecorator("[fixed_field]firstname", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.firstname
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    {
                      max: 18,
                      message:
                        "The First Name may not be greater than 18 characters.",
                    },
                    { whitespace: true, message: "This Field cannot be empty" },
                  ],
                })(
                  <Input
                    onChange={this.onChangeFirstName}
                    className="field_custome_hight"
                    placeholder="Enter First Name"
                    style={{ textTransform: "capitalize" }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">Last Name</span>}
              >
                {getFieldDecorator("[fixed_field]lastname", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.lastname
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    {
                      max: 18,
                      message:
                        "The Last Name may not be greater than 18 characters.",
                    },
                    { whitespace: true, message: "This Field cannot be empty" },
                  ],
                })(
                  <Input
                    onChange={this.onChangeLastName}
                    className="field_custome_hight"
                    placeholder="Enter Last Name"
                    style={{ textTransform: "capitalize" }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">Email Address</span>}
              >
                {getFieldDecorator("[fixed_field]email", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.email
                      : [],
                  rules: [
                    {
                      required: true,
                      message: "This Field is Required!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid e-mail address.",
                    },
                    { whitespace: true, message: "This Field cannot be empty" },
                  ],
                })(
                  <Input
                    onChange={this.onChangeEmail}
                    className="field_custome_hight"
                    placeholder="Enter Email Address"
                    // style={{ textTransform: "lowercase" }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">Password</span>}
                hasFeedback
              >
                {getFieldDecorator("[fixed_field]password", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.password
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "This Field is Required!",
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                    {
                      pattern: /^(?=.*\d)(?=.*[0-9])(?=.*[a-zA-Z]).{8,}/,
                      message:
                        "Password should be a minimum of 8 characters, including at least one number.",
                    },
                    // {
                    //   min: 8,
                    //   message: "The Password must be at least 8 characters."
                    // }
                  ],
                })(
                  <Input.Password
                    className="field_custome_hight"
                    placeholder="Enter Password"
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">Retype Password</span>}
                hasFeedback
              >
                {getFieldDecorator("[fixed_field]confirm", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.confirm
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "This Field is Required!",
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(
                  <Input.Password
                    className="field_custome_hight"
                    onBlur={this.handleConfirmBlur}
                    placeholder="Retype Password"
                  />
                )}
              </Form.Item>

              <Form.Item
                label={<span className="text_semibold">Your Role</span>}
              >
                {getFieldDecorator("[fixed_field]user_role", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.user_role
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                  ],
                })(
                  <Select
                    className="field_custome_hight"
                    showSearch
                    placeholder="Select Role"
                    optionFilterProp="children"
                    onChange={this.roleChange}
                  >
                    {userRole &&
                      userRole.map((user_role) => (
                        <Option value={user_role.id}>{user_role.name}</Option>
                      ))}
                  </Select>
                )}
              </Form.Item>

              {this.state.user_role && (
                <>
                  <Form.Item
                    label={
                      <span className="text_semibold">
                        What is your primary medical specialty?
                      </span>
                    }
                    labelCol={{ span: "24" }}
                  >
                    {getFieldDecorator("[fixed_field]specialty", {
                      initialValue:
                        formFields && formFields.fixed_field
                          ? formFields.fixed_field.specialty
                          : [],
                      rules: [
                        {
                          required: true,
                          message: "This Field is Required!",
                        },
                      ],
                    })(
                      <Select
                        className="field_custome_hight"
                        showSearch
                        placeholder="Select Speciality"
                        optionFilterProp="children"
                      >
                        {speciality
                          .filter((e) => e.name !== "Other")
                          .map((sep) => (
                            <Option value={sep.id}>{sep.name}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                  {this.state.user_role === 3 && (
                    <Form.Item
                      label={
                        <span className="text_semibold">
                          What is your sub-specialty?
                        </span>
                      }
                      labelCol={{ span: "24" }}
                    >
                      {getFieldDecorator("[fixed_field]sub_specialty", {
                        initialValue:
                          formFields && formFields.fixed_field
                            ? formFields.fixed_field.sub_specialty
                            : [],
                        rules: [{ required: false }],
                      })(
                        // <Input
                        //   className="field_custome_hight"
                        //   placeholder="Enter sub speciality"
                        //   style={{ textTransform: "capitalize" }}
                        // />
                        <Select
                          className="field_custome_hight"
                          showSearch
                          placeholder="Select Speciality"
                          optionFilterProp="children"
                        >
                          {speciality.map((sep) => (
                            <Option value={sep.id}>{sep.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  )}
                </>
              )}
              <Form.Item
                label={
                  <span className="text_semibold">
                    Please select the approximate month and year that you
                    started clinical practice since completing your medical
                    training, residency or fellowship.
                  </span>
                }
                labelCol={{ span: 24 }}
              >
                {getFieldDecorator("[fixed_field]experience_year", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.experience_year
                      : this.state.selectedyear &&
                        moment(this.state.selectedyear),
                  rules: [
                    {
                      required: true,
                      message: "This Field is Required!",
                    },
                  ],
                })(<MonthPicker disabledDate={this.disabledDate} />)}
              </Form.Item>

              <Form.Item
                label={<span className="text_semibold">Time Zone</span>}
              >
                {getFieldDecorator("[fixed_field]timezone", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.timezone
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                  ],
                })(
                  <Select
                    className="field_custome_hight"
                    showSearch
                    placeholder="Select Time Zone"
                    optionFilterProp="children"
                  >
                    {timezone.map((tz) => (
                      <Option value={tz.id}>{tz.name}</Option>
                    ))}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label={
                  <>
                    <span className="text_semibold">Payment Preference</span>
                    <span>
                      <Tooltip placement="rightTop" title={"Immediate Digital Payment is made via virtual Mastercard gift card or direct-to-debit.  There is a $49 minimum threshold and a $5.00 fee to use the direct-to-debit option - no fee applies for virtual gift cards.  Standard check payments can take from 2-4 weeks for processing and receipt."}>
                          <i className="icon-info text_app_color_light" style={{ fontSize: "18px" }} />
                      </Tooltip>
                    </span>
                  </>
                }
              >
                {getFieldDecorator("[fixed_field]Payment_preference", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.Payment_preference
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                  ],
                })(
                  <Select
                    className="field_custome_hight"
                    showSearch
                    placeholder="Select Payment Preference"
                    optionFilterProp="children"
                    onChange={this.handlePaymentChange}
                  >
                    <Option value="1">Standard Check</Option>

                    {/* <Option value="2">Paypal</Option> */}
                    <Option value="3">Immediate Digital Payment</Option>
                  </Select>
                )}
              </Form.Item>
              {this.state.payment === "1" && (
                <div>
                  <Form.Item
                    label={<span className="text_semibold">First Name</span>}
                  >
                    {getFieldDecorator("[fixed_field]bank_firstname", {
                      initialValue:
                        formFields && formFields.fixed_field
                          ? formFields.fixed_field.bank_firstname
                          : this.state.first_name,
                      rules: [
                        { required: true, message: "This Field is Required!" },
                        {
                          whitespace: true,
                          message: "This Field cannot be empty",
                        },
                      ],
                    })(
                      <Input
                        className="field_custome_hight"
                        placeholder="Enter First Name"
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    label={<span className="text_semibold">Last Name</span>}
                  >
                    {getFieldDecorator("[fixed_field]bank_lastname", {
                      initialValue:
                        formFields && formFields.fixed_field
                          ? formFields.fixed_field.bank_lastname
                          : this.state.last_name,
                      rules: [
                        { required: true, message: "This Field is Required!" },
                        {
                          whitespace: true,
                          message: "This Field cannot be empty",
                        },
                      ],
                    })(
                      <Input
                        className="field_custome_hight"
                        placeholder="Enter Last Name"
                      />
                    )}
                  </Form.Item>
                </div>
              )}
              <Form.Item
                label={<span className="text_semibold">Street Address</span>}
              >
                {getFieldDecorator("[fixed_field]bank_streetaddress", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.bank_streetaddress
                      : null,
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    {
                      whitespace: true,
                      message: "This Field cannot be empty",
                    },
                  ],
                })(
                  <Input
                    className="field_custome_hight"
                    placeholder="Enter Street Address"
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">APT/ Unit no.</span>}
              >
                {getFieldDecorator("[fixed_field]bank_apt", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.bank_apt
                      : null,
                  rules: [{ required: false }],
                })(
                  <Input
                    className="field_custome_hight"
                    placeholder="Enter APT/ Unit no."
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<span className="text_semibold">Zip Code</span>}
              >
                {getFieldDecorator("[fixed_field]bank_zipcode", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.bank_zipcode
                      : null,
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    {
                      whitespace: true,
                      message: "This Field cannot be empty",
                    },
                  ],
                })(
                  <Input
                    className="field_custome_hight"
                    placeholder="Enter Zip Code"
                  />
                )}
              </Form.Item>
              <Form.Item label={<span className="text_semibold">City</span>}>
                {getFieldDecorator("[fixed_field]bank_city", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.bank_city
                      : null,
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    {
                      whitespace: true,
                      message: "This Field cannot be empty",
                    },
                  ],
                })(
                  <Input
                    className="field_custome_hight"
                    placeholder="Enter City"
                  />
                )}
              </Form.Item>
              <Form.Item label={<span className="text_semibold">State</span>}>
                {getFieldDecorator("[fixed_field]bank_state", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.bank_state
                      : [],
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    // {
                    //   whitespace: true,
                    //   message: "This Field cannot be empty",
                    // },
                  ],
                })(
                  <Select
                    className="field_custome_hight"
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                  >
                    {state &&
                      state.map((e) => (
                        <Option value={e.id}>
                          {e.short_code}-{e.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <>
                {/* {this.state.payment === "2" ? (
                <div>
                  <Form.Item
                    label="Paypal Email Address"
                    // style={{ marginBottom: 0 }}
                  >
                    {getFieldDecorator("[fixed_field]paypal_email", {
                      initialValue:
                        formFields && formFields.fixed_field
                          ? formFields.fixed_field.paypal_email
                          : this.state.email_address,
                      rules: [
                        {
                          type: "email",
                          message: "The input is not valid E-mail Address!"
                        },
                        {
                          required: false,
                          message: "This Field is Required!"
                        }
                      ]
                    })(
                      <Input
                        onChange={this.onChangepaypal}
                        className="field_custome_hight"
                        placeholder="Enter Paypal Email Address"
                      />
                    )}
                  </Form.Item>
                  <div
                    style={{
                      borderTop: "1px solid #D9D9D9",
                      position: "relative",
                      marginBottom: "10px"
                    }}
                  >
                    <div
                      style={{
                        margin: "-10px auto 0 auto",
                        width: "100%",
                        padding: "0 5px",
                        position: "absolute"
                      }}
                      className="text_center"
                    >
                      <span className="bg_white" style={{ padding: "0 7px" }}>
                        or
                      </span>
                    </div>
                  </div>
                  <Form.Item label={<span className="text_semibold">Paypal ID</span>}>
                    {getFieldDecorator("[fixed_field]paypal_id", {
                      initialValue:
                        formFields && formFields.fixed_field
                          ? formFields.fixed_field.paypal_id
                          : [],
                      rules: [
                        { required: false, message: "This Field is Required!" }
                      ]
                    })(
                      <Input
                        onChange={this.onChangepaypal}
                        className="field_custome_hight"
                        placeholder="Enter Paypal ID"
                      />
                    )}
                  </Form.Item>
                </div>
              ) : null} */}
              </>

              <Form.Item
                label={<span className="text_semibold">Phone Number</span>}
              >
                {getFieldDecorator("[fixed_field]phoneno", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.phoneno
                      : null,
                  rules: [
                    { required: true, message: "This Field is Required!" },
                    // {
                    //   pattern: /^(\s*|\d{8,10})$/,
                    //   message:
                    //     "The phone number should be 10-digits, including the area code.",
                    // },
                    {
                      pattern: /^(\s*|\d{10})$/,
                      message:
                        "The phone number should be 10-digits, including the area code.",
                    },
                  ],
                })(
                  <Input
                    type="tel"
                    onChange={this.onChangePhone}
                    // addonBefore="+1"
                    addonBefore={
                      <span style={{ display: "flex" }}>
                        {getFieldDecorator("[fixed_field]phone_option", {
                          initialValue:
                            formFields && formFields.fixed_field
                              ? formFields.fixed_field.phone_option
                              : 1,
                        })(
                          <Select style={{ width: 82 }}>
                            {phoneOptions &&
                              phoneOptions.map((e) => (
                                <Option value={e.id}>{e.name}</Option>
                              ))}
                          </Select>
                        )}
                        <Divider
                          type="vertical"
                          orientation="center"
                          style={{
                            height: "auto",
                            background: "#d9d9d9",
                            top: "0",
                          }}
                        />
                        <div>+1</div>
                      </span>
                    }
                    // type="number"
                    className="field_custome_hight"
                    placeholder="Enter Phone Number"
                  />
                )}
              </Form.Item>

              <Form.Item
                label={
                  <span className="text_semibold">Alternative number</span>
                }
              >
                {getFieldDecorator("[fixed_field]alternative_phoneno", {
                  initialValue:
                    formFields && formFields.fixed_field
                      ? formFields.fixed_field.alternative_phoneno
                      : null,
                  rules: [
                    {
                      pattern: /^(\s*|\d{10})$/,
                      message:
                        "The phone number should be 10-digits, including the area code.",
                    },
                  ],
                })(
                  <Input
                    type="tel"
                    onChange={this.onChangeAlterNativePhone}
                    // addonBefore="+1"
                    addonBefore={
                      <span style={{ display: "flex" }}>
                        {getFieldDecorator(
                          "[fixed_field]alternative_phone_option",
                          {
                            initialValue:
                              formFields && formFields.fixed_field
                                ? formFields.fixed_field
                                    .alternative_phone_option
                                : 1,
                          }
                        )(
                          <Select style={{ width: 82 }}>
                            {phoneOptions &&
                              phoneOptions.map((e) => (
                                <Option value={e.id}>{e.name}</Option>
                              ))}
                          </Select>
                        )}
                        <Divider
                          type="vertical"
                          orientation="center"
                          style={{
                            height: "auto",
                            background: "#d9d9d9",
                            top: "0",
                          }}
                        />
                        <div>+1</div>
                      </span>
                    }
                    // type="number"
                    className="field_custome_hight"
                    placeholder="Enter Phone Number"
                  />
                )}
              </Form.Item>

              {user_role === 3 && (
                <Form.Item
                  label={<span className="text_semibold">ME Number</span>}
                >
                  {getFieldDecorator("[fixed_field]me_number", {
                    initialValue:
                      formFields && formFields.fixed_field
                        ? formFields.fixed_field.me_number
                        : null,
                    rules: [
                      {
                        pattern: /^(?:\d{11}|)$/,
                        message:
                          "Please enter the ME number in the correct format.",
                      },
                      // { whitespace: true, message: "This Field cannot be empty" },
                    ],
                  })(
                    <Input
                      className="field_custome_hight"
                      placeholder="Enter ME Number"
                    />
                  )}
                </Form.Item>
              )}

              {(user_role === 3 || user_role === 4 || user_role === 6) && (
                <Form.Item
                  label={<span className="text_semibold">DEA Number</span>}
                >
                  {getFieldDecorator("[fixed_field]dea_number", {
                    initialValue:
                      formFields && formFields.fixed_field
                        ? formFields.fixed_field.dea_number
                        : null,
                    rules: [
                      {
                        required: true,
                        message: "This Field is Required!",
                      },
                      {
                        pattern: /^[a-zA-Z]{2}\d{7}$/,
                        message:
                          "Please enter the DEA number in the correct format.",
                      },
                      {
                        whitespace: true,
                        message: "This Field cannot be empty",
                      },
                    ],
                  })(
                    <Input
                      onChange={this.onChangeEmail}
                      className="field_custome_hight"
                      placeholder="Enter DEA Number"
                    />
                  )}
                </Form.Item>
              )}

              {
                user_role && 
                  <Form.Item
                  label={<span className="text_semibold">NPI Number</span>}
                >
                  {getFieldDecorator("[fixed_field]npi_number", {
                    initialValue:
                      formFields && formFields.fixed_field
                        ? formFields.fixed_field.npi_number
                        : null,
                    rules: [
                      { required: (user_role === 3 || user_role === 4 || user_role === 6), message: "This Field is Required!" },
                      {
                        whitespace: true,
                        message: "This Field cannot be empty",
                      },
                      {
                        pattern: /^(?:\d{10}|)$/,
                        message:
                          "Please enter the NPI number in the correct format.",
                      },
                    ],
                  })(
                    <Input
                      className="field_custome_hight"
                      placeholder="Enter NPI Number"
                    />
                  )}
                </Form.Item>
              }
            
            </div>
            {/* dynamic question start */}
            <FormData form={form} data={data} formFields={formFields} />
            {/* dynamic question end */}

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              <Text className="text_medium" style={{ color: "#6B6E71" }}>
                For questions or support please contact -
                support@sentimentglobal.com
              </Text>
            </div>
          </div>
        ) : (
          <Spin
            className="register_stepper_form"
            size="large"
            style={{ width: "-webkit-fill-available", padding: "7em 0" }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    formField: state.formField,
  };
};

export default connect(mapStateToProps)(Step1);
